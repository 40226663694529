html {
  background-color: #202020;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slider-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slider-container::-webkit-scrollbar {
  display: none;
}

.custom-dots li button:before {
  color: white;
  font-size: 10px;
}

.custom-dots li.slick-active button:before {
  color: #f50057;
}

.tile {
  width: 100%;
  max-width: 90%; 
  margin: 0 auto; 
  box-sizing: border-box; 
}

.slick-track {
  display: flex !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.slick-slide {
  background: #202020;
  border-radius: 8px;
  margin: 0 8px;
  max-height: 383px;
  box-sizing: border-box;
}

.slick-prev{
  left: -45px;
}

.slick-prev:before {
  content: "" !important;
  border: solid rgb(255, 255, 255);
  border-width: 0 3px 3px 0;
  display: inline-block;
  font-size: 10px;
  padding: 3px;
  color: transparent;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.slick-next{
  right: -45px;
}

.slick-next:before {
  content: "" !important;
  border: solid rgb(255, 255, 255);
  border-width: 0 3px 3px 0;
  display: inline-block;
  font-size: 10px;
  padding: 3px;
  color: transparent;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.slick-list {
  overflow: hidden;
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-background {
  background: linear-gradient(135deg, rgba(0, 0, 0, 1), rgba(0, 0, 60, 0.9));
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
}

@media screen and (max-width: 898px) {
  .featured-container {
    flex-direction: column;
    flex-wrap: nowrap !important;
  }
  .tag-navbar {
    justify-content: start !important;
  }
  .post-tile-container:hover {
    transform: scale(0.9);
  }
}

@media screen and (min-width: 898px) {
  .featured-container {
    flex-direction: row;
    flex-wrap: nowrap !important;
  }
  .tag-navbar {
    justify-content: center !important;
  }
  .post-tile-container:hover {
    transform: scale(0.95) !important;
  }
}

a:visited,
a:link {
  color: inherit;
  text-decoration: none;
}

.tagItem {
  transition: all 600ms ease;
  background-color: rgb(30, 30, 30);
}

.tagItem:hover {
  background-color: rgb(40, 40, 40) !important;
}

.post-tile-container {
  transition: all 600ms ease;
  transform: scale(0.9);
}

.image-delete {
  transition: all 300ms ease;
}

.image-delete:hover {
  color: #c30606 !important;
  font-weight: bold !important;
}

.link {
  color: #1e4083 !important;
}

.link:visited {
  color: #1e4083 !important;
}

.ql-video {
  width: 100%;
  aspect-ratio: 16 / 9;
  margin: 2rem 0;
}

.ql-formats .ql-video {
  width: revert !important;
  margin: revert !important;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.ql-tooltip {
  left: 0 !important;
}